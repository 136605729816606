import {useDefaultMenuId} from '@data/queries/categories/getDefaultMenu.main'
import {useUserPreferencesPartial} from '@data/queries/preferences/userPreferences.main'
import useWebsiteId from './useWebsiteId'

export default function useMenuId(): string {
  const websiteId = useWebsiteId()
  const preferencesV2 = useUserPreferencesPartial(websiteId)
  const defaultMenuId = useDefaultMenuId()
  const {menuId} = preferencesV2?.data?.userPreferences ?? {}

  return menuId || defaultMenuId
}
