import {WebsiteInitialData_CachedQuery} from '@data/__generated__/types.main'
import {NextRouter} from 'next/router'

export default function getOrderPath(
  router: NextRouter,
  website: WebsiteInitialData_CachedQuery['website']
) {
  if (typeof router.query.isChat === 'string' && router.query.isChat === 'true') {
    return `/chat/${website._id}/order`
  }
  return '/pedir'
}
