import React from 'react'
import ClientSuspense from '@components/ClientSuspense'
import {
  GetStoreCapacityForNextOpenDateQuery,
  GetStoreCapacityForNextOpenDateQueryVariables,
  GetStoreIsOpenNow_CachedQuery,
  GetStoreIsOpenNow_CachedQueryVariables
} from '@data/__generated__/types.main'
import {useUserPreferences} from '@data/queries/preferences/userPreferences.main'
import dayjs from '@helpers/dayjs'
import isStoreOpenNow from '@helpers/websites/isStoreOpenNow'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'
import {useTranslation} from 'next-i18next'

import styles from './styles.module.css'

export interface Props {
  className?: string
}

function NextOpenDateInner(props: Props) {
  const {t} = useTranslation('website', {keyPrefix: 'order.nextOpenDate'})
  const {storeId, deliverAt} = useUserPreferences()

  const websiteId = useWebsiteId()

  const {store: storeIsOpenNow} = useQuery<
    GetStoreIsOpenNow_CachedQuery,
    GetStoreIsOpenNow_CachedQueryVariables
  >({
    query: gql`
      query getStoreIsOpenNow_cached($storeId: ID!, $websiteId: ID!) {
        store(storeId: $storeId) {
          _id
          schedule {
            itsOpenNow: itsAvailableAt(websiteId: $websiteId)
          }
        }
      }
    `,
    variables: {
      storeId,
      websiteId
    },
    fetchPolicy: 'cache-first',
    omit: !storeId
  })

  const itsOpenNow = isStoreOpenNow(storeIsOpenNow)

  const {store, website} = useQuery<
    GetStoreCapacityForNextOpenDateQuery,
    GetStoreCapacityForNextOpenDateQueryVariables
  >({
    query: gql`
      query getStoreCapacityForNextOpenDate($storeId: ID!, $websiteId: ID!, $deliverAt: Date) {
        store(storeId: $storeId) {
          _id
          nextAvailableDate
          schedulingOrdersDays
          hasOrderCapacity(date: $deliverAt)
        }
        website(websiteId: $websiteId) {
          _id
          limitOrdersBySlot
        }
      }
    `,
    variables: {
      storeId,
      websiteId,
      deliverAt
    },
    fetchPolicy: 'cache-first',
    omit: itsOpenNow || !storeId
  })

  if (itsOpenNow || !store) return null

  const closedMessage =
    !store?.hasOrderCapacity && website?.limitOrdersBySlot
      ? 'Hemos recibido todos los pedidos que podemos procesar en este momento, ¿Qué tal si programas tu pedido para más tarde?'
      : null

  const nextDate = store.nextAvailableDate
  const schedulingOrdersDays = store.schedulingOrdersDays

  const className = [styles.container, props.className].join(' ')
  const schedulingClassName = [styles.scheduling, props.className].join(' ')

  if (closedMessage) {
    return <div className={schedulingClassName}>{closedMessage}</div>
  }

  if (!nextDate) {
    return <div className={className}>{t('labelClosedNow')}</div>
  }

  const date = dayjs(nextDate)

  if (deliverAt && dayjs(deliverAt).isAfter(date) && website?.limitOrdersBySlot) {
    return null
  }

  if (date.isSame(dayjs(), 'day')) {
    if (schedulingOrdersDays) {
      return (
        <div className={schedulingClassName}>
          {t('labelScheduleOrderAfter', {date: date.format('HH:mm')})}
        </div>
      )
    }

    return <div className={className}>{t('labelClosedUntil', {date: date.format('HH:mm')})}</div>
  }

  if (date.isSame(dayjs().add(1, 'day'), 'day')) {
    if (schedulingOrdersDays >= 2) {
      return <div className={schedulingClassName}>{t('labelScheduleOrderTomorrow')}</div>
    }

    return (
      <div className={className}>{t('labelClosedUntilTomorrow', {date: date.format('HH:mm')})}</div>
    )
  }

  if (schedulingOrdersDays >= 3) {
    return (
      <div className={schedulingClassName}>
        {t('labelScheduleOrderFrom', {date: date.format('dddd D [de] MMMM')})}
      </div>
    )
  }

  return (
    <div className={className}>
      {t('labelClosedUntilDate', {
        date: date.format('LL'),
        hour: date.format('HH:mm')
      })}
    </div>
  )
}

export default function NextOpenDate(props: Props) {
  return (
    <ClientSuspense fallback={null}>
      <NextOpenDateInner {...props} />
    </ClientSuspense>
  )
}
