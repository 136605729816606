import React from 'react'
import Image from '@components/Image'
import get from 'lodash/get'

export default function BrandBar(props) {
  const imgSrc = get(props, 'brand.menuImage.url')
  const brandName = get(props, 'brand.name')
  const altText = `Banner ${brandName}`
  const imgLink = get(props, 'brand.link')

  if (!imgSrc) return null
  if (imgLink) {
    return (
      <a href={imgLink} target="_blank" rel="noreferrer">
        <div className="w-full h-64 relative">
          <Image layout="fill" objectFit="contain" alt={altText} src={imgSrc} />
        </div>
      </a>
    )
  }
  return (
    <div className="w-full h-64 relative">
      <Image layout="fill" objectFit="contain" alt={altText} src={imgSrc} />
    </div>
  )
}
