import {
  StoreNamePreferencesQuery,
  StoreNamePreferencesQueryVariables,
} from '@data/__generated__/types.main'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export default function useStoreName() {
  const websiteId = useWebsiteId()

  const {preferences} = useApolloQuery<
    StoreNamePreferencesQuery,
    StoreNamePreferencesQueryVariables
  >({
    query: gql`
      query storeNamePreferences($websiteId: ID!) {
        preferences: userPreferences(websiteId: $websiteId) {
          _id
          store {
            _id
            fullName
          }
        }
      }
    `,
    variables: {
      websiteId,
    },
    fetchPolicy: 'cache-first',
  })

  return preferences?.store
}
