import {createContext, useContext, useState} from 'react'

export const ProductPaginationContext = createContext<{
  productPaginationMode: 'auto' | 'manual'
  setProductPaginationMode: (mode: 'auto' | 'manual') => void
  sorting: any
  setSorting: (sorting: any) => void
  filter: string
  setFilter: (filter: string) => void
}>({
  productPaginationMode: 'auto',
  setProductPaginationMode: () => {},
  sorting: null,
  setSorting: () => {},
  filter: '',
  setFilter: () => {},
})

export const ProductPaginationContextProvider = ({children}) => {
  const [productPaginationMode, setProductPaginationMode] = useState<'auto' | 'manual'>('auto')
  const [sorting, setSorting] = useState<any>(null)
  const [filter, setFilter] = useState<string>('')

  return (
    <ProductPaginationContext.Provider
      value={{
        productPaginationMode,
        setProductPaginationMode,
        sorting,
        setSorting,
        filter,
        setFilter,
      }}
    >
      {children}
    </ProductPaginationContext.Provider>
  )
}

export const useProductPaginationContext = () => {
  return useContext(ProductPaginationContext)
}
