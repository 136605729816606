import {useUserPreferencesPartial} from '@data/queries/preferences/userPreferences.main'
import isServerSide from '@helpers/misc/isServerSide'
import useInitialData from '@page-components/Layout/useInitialData'

export default function useShouldHidePrice() {
  const preferences = useUserPreferencesPartial()
  const {website} = useInitialData()

  if (!website.dontShowPricesUntilStoreSelected) {
    return false
  }

  if (isServerSide()) {
    // Avoid hydration warnings when the server preferences does not have a storeId
    return false
  }

  if (preferences.loading) {
    return false
  }
  return !preferences.data?.userPreferences?.storeId
}
