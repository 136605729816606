import {useSuspenseQuery} from '@apollo/client'
import {GetDefaultMenuIdQuery, GetDefaultMenuIdQueryVariables} from '@data/__generated__/types.main'
import useWebsiteId from '@hooks/useWebsiteId'
import gql from 'graphql-tag'

export const defaultMenuQuery = gql`
  query getDefaultMenuId($websiteId: ID!) {
    defaultMenu(websiteId: $websiteId) {
      _id
    }
  }
`
export function useDefaultMenuId() {
  const websiteId = useWebsiteId()
  const result = useSuspenseQuery<GetDefaultMenuIdQuery, GetDefaultMenuIdQueryVariables>(
    defaultMenuQuery,
    {
      variables: {websiteId},
      fetchPolicy: 'cache-first'
    }
  )

  return result.data?.defaultMenu?._id
}
