import {useQuery} from '@apollo/client'
import {
  Categories_CachedQuery,
  Categories_CachedQueryVariables,
} from '@data/__generated__/types.main'
import useMenuId from '@hooks/useMenuId'
import useWebsiteId from '@hooks/useWebsiteId'
import gql from 'graphql-tag'

export default function useCategoriesMinimal() {
  const websiteId = useWebsiteId()
  const menuId = useMenuId()

  const {data} = useQuery<Categories_CachedQuery, Categories_CachedQueryVariables>(
    gql`
      query categories_cached($websiteId: ID!, $menuId: ID!, $productLimit: Float!) {
        categories: categoriesInMenu(websiteId: $websiteId, menuId: $menuId) {
          items {
            _id
            name
            products(menuId: $menuId, limit: $productLimit) {
              _id
            }
          }
        }
      }
    `,
    {
      variables: {
        websiteId,
        menuId,
        // Important: Must be different than getCategoriesAndProductsWeb_cached's productLimit (currently set at PRODUCTS_PAGE_SIZE)
        productLimit: 1,
      },
      // Initially will only contain the first page of categories loaded in useCategoriesAndProducts
      fetchPolicy: 'cache-and-network',
    },
  )

  return data?.categories?.items?.filter(cat => cat.products.length > 0)
}
