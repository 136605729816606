import {useWebsiteMenuInformation} from '@data/queries/website/websiteMenuInformation.main'
import React from 'react'
import {MdStore} from 'react-icons/md'

import useStoreName from './useStoreName.main'

import styles from './styles.module.css'

export interface Props {
  showTitle?: boolean
  isOnMenu?: boolean
  isCheckoutV2?: boolean
}

function StoreNameInner({showTitle = false, isOnMenu = false, isCheckoutV2 = false}: Props) {
  const {website} = useWebsiteMenuInformation()
  const store = useStoreName()
  const {showStoreName} = website || {}

  if (!showStoreName) return null
  if (!store || !store.fullName) return null

  let classNameComponent = styles.containerIcon

  if (isOnMenu) {
    classNameComponent = styles.menu
  }

  if (isCheckoutV2) {
    classNameComponent = styles.checkoutV2
  }

  return (
    <>
      {showTitle ? <div className={styles.title}>Local</div> : null}
      <div className={classNameComponent} suppressHydrationWarning>
        <MdStore size={20} className="mr-1" /> {store.fullName}
      </div>
    </>
  )
}

export default function StoreName(props: Props) {
  return <StoreNameInner {...props} />
}
