import React from 'react'
import classnames from '@helpers/misc/classnames'

export default function LoadingProducts({fullScreen}: {fullScreen?: boolean}) {
  return (
    <div
      className={classnames({
        'col-xs-12 col-sm-6 col-md-8': !fullScreen,
        'col-xs-12': fullScreen
      })}>
      <div className="flex flex-col animate-pulse">
        <div className="my-9 h-14 w-full bg-gray-300 dark:bg-zinc-600 rounded"></div>
        <div className="flex justify-end">
          <div className="mt-1 mb-3 h-12 w-40 bg-gray-300 dark:bg-zinc-600 rounded"></div>
        </div>

        {/* Category */}
        <div>
          <div className="mt-1 h-6 w-24 bg-gray-300 dark:bg-zinc-600 rounded"></div>
          <div className="relative rounded-xl overflow-auto p-8">
            <div className="flex flex-row space-x-4">
              <div className="basis-full md:basis-1/2 h-[152px] flex items-center justify-center bg-gray-300 dark:bg-zinc-600 rounded"></div>
              <div className="basis-full md:basis-1/2 h-[152px] flex items-center justify-center bg-gray-300 dark:bg-zinc-600 rounded"></div>
            </div>
            <div className="flex flex-row space-x-4 mt-6">
              <div className="basis-full md:basis-1/2 h-[152px] flex items-center justify-center bg-gray-300 dark:bg-zinc-600 rounded"></div>
              <div className="basis-full md:basis-1/2 h-[152px] flex items-center justify-center bg-gray-300 dark:bg-zinc-600 rounded"></div>
            </div>
          </div>
        </div>
        {/* Category */}
        <div>
          <div className="mt-1 mb-5 h-6 w-24 bg-gray-300 dark:bg-zinc-600 rounded"></div>
          <div className="relative rounded-xl overflow-auto p-8">
            <div className="flex flex-row space-x-4">
              <div className="basis-full md:basis-1/2 h-[152px] flex items-center justify-center bg-gray-300 dark:bg-zinc-600 rounded"></div>
              <div className="basis-full md:basis-1/2 h-[152px] flex items-center justify-center bg-gray-300 dark:bg-zinc-600 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
