import classnames from '@helpers/misc/classnames'
import getQueryObject from '@helpers/misc/getQueryObject'
import useDebounce from '@hooks/useDebounce'
import useIsomorphicLayoutEffect from '@hooks/useIsomorphicLayoutEffect'
import useKeyboardEvent from '@hooks/useKeyboardEvent'
import useRef from '@hooks/useRef'
import useState from '@hooks/useState'
import Container from '@packages/justo-parts/lib/components/Container'
import useNavbarV2 from '@page-components/Layout/Website/Layouts/Default/NavbarV2/hooks/useNavbarV2'
import useCartV3 from '@page-components/Order/CartV3/hooks/useCartV3'
import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'
import {useEffect} from 'react'
import {MdClose, MdSearch} from 'react-icons/md'

import useLayout from '@page-components/Order/useLayout'
import styles from './styles.module.css'

export default function Bar(props: {
  filter: string
  setFilter: (string) => void
  children: React.ReactNode
}) {
  const {filter, setFilter} = props
  const layout = useLayout()
  const [localSearch, setLocalSearch] = useState(filter)
  const [searching, setSearching] = useState(false)
  const input = useRef(null)
  const {t} = useTranslation('website')
  const router = useRouter()
  const [debouncedFilter, _isDebouncing] = useDebounce(localSearch, 400)
  const isCartV3 = useCartV3()
  const isNavbarV2Active = useNavbarV2()
  const q = getQueryObject(router)?.search

  useKeyboardEvent('Escape', () => {
    setSearching(false)
  })

  useEffect(() => {
    const qValue = Array.isArray(q) ? q[0] : q

    if (qValue && qValue !== '' && filter !== qValue) {
      setFilter(qValue)
      setLocalSearch(qValue)
    }
    if (qValue) {
      setSearching(true)
    }
  }, [q]) // We want to do this only on load

  useEffect(() => {
    if (debouncedFilter !== filter) {
      setFilter(debouncedFilter)
      if (debouncedFilter && debouncedFilter !== getQueryObject(router)?.search) {
        const url = new URL(window.location.href)
        url.searchParams.set('search', debouncedFilter)
        window.history.pushState(null, '', url.toString())
      }
    }
  }, [debouncedFilter, setFilter, filter, router])

  useIsomorphicLayoutEffect(() => {
    if (searching) {
      input.current.focus()
    } else {
      setFilter('')
      setLocalSearch('')
    }
  }, [searching])

  const containerClassName = {
    v1: classnames(styles.container, {'!top-[88px]': isNavbarV2Active}),
    v2: classnames(styles.withBarContainer, {'!top-[88px]': isNavbarV2Active}),
    mobile: classnames(styles.withBarContainerMobile, {
      '!top-0': isCartV3,
    }),
  }[layout]

  const content = (
    <>
      {/* biome-ignore lint/a11y/useKeyWithClickEvents: */}
      <div
        className={styles.search}
        onClick={() => {
          setSearching(!searching)
        }}
      >
        <MdSearch size={20} />
      </div>
      {searching ? (
        <>
          <input
            ref={input}
            className={styles.input}
            value={localSearch}
            onChange={event => setLocalSearch(event.target.value)}
            type="text"
            placeholder={t('order.labelSearchProduct')}
          />
          {/* biome-ignore lint/a11y/useKeyWithClickEvents: */}
          <div className={styles.close} onClick={() => setSearching(!searching)}>
            <MdClose size={20} />
          </div>
        </>
      ) : (
        props.children
      )}
    </>
  )

  if (layout === 'v1') {
    return (
      <div className={containerClassName}>
        <div className={styles.inner}>{content}</div>
      </div>
    )
  }

  return (
    <div className={containerClassName}>
      <Container className={styles.inner}>{content}</Container>
    </div>
  )
}
