import React from 'react'
import get from 'lodash/get'

import styles from './styles.module.css'

export default function BrandBar(props) {
  const imgSrc = get(props, 'brand.categoriesImage.url')

  return imgSrc ? (
    <img className={styles.image} onClick={props.onClick} src={props.brand.categoriesImage.url} />
  ) : null
}
