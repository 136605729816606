import Select from '@packages/justo-parts/lib/components/fields/Select'
import classnames from 'classnames'
import {useTranslation} from 'next-i18next'
import {Field} from 'simple-react-form'

import {useProductPaginationContext} from '../ProductPaginationContext'
import styles from './styles.module.css'

const encode = val => JSON.stringify(val, null, 2)
const parse = val => JSON.parse(val)

const options = [
  {value: JSON.stringify(null), label: 'Destacados'},
  {value: encode({type: 'timesPurchased', order: 'desc'}), label: 'Más vendidos'},
  {value: encode({type: 'price', order: 'asc'}), label: 'Precio menor a mayor'},
  {value: encode({type: 'price', order: 'desc'}), label: 'Precio mayor a menor'},
  {value: encode({type: 'name', order: 'asc'}), label: 'Ordenar A - Z'},
  {value: encode({type: 'name', order: 'desc'}), label: 'Ordenar Z - A'},
]

export default function Filters(props: {
  additionalClassname?: string
  sorting?: any
  setSorting?: any
}) {
  const {sorting: contextSorting, setSorting: contextSetSorting} = useProductPaginationContext()
  const {additionalClassname, sorting = contextSorting, setSorting = contextSetSorting} = props
  const {t} = useTranslation('website', {keyPrefix: 'order.products.productsContent.filters'})
  return (
    <div className={classnames(styles.container, additionalClassname ? additionalClassname : '')}>
      <div className={styles.order}>
        <Field
          fieldName=""
          placeholder={t('labelSort')}
          isClearable={false}
          onChange={val => setSorting(parse(val))}
          value={encode(sorting)}
          type={Select}
          options={options}
        />
      </div>
    </div>
  )
}
